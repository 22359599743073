// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s, visibility 0s 0.15s;
}
:host[absolute] {
  position: absolute;
}
:host.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.15s, visibility 0s;
  pointer-events: initial;
}
:host.hidden {
  display: none;
  animation: none;
  opacity: 0;
  visibility: hidden;
}
:host .message {
  color: rgba(0, 0, 0, 0.54);
  margin-top: 32px;
  font-weight: 500;
}
:host .lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  opacity: 0.75;
  text-align: center;
}
:host .lds-dual-ring::after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px auto;
  border-radius: 50%;
  border: 5px solid;
  border-color: var(--ion-color-primary) transparent var(--ion-color-primary) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
