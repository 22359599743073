// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .corner-ribbon {
  pointer-events: none;
  width: 160px;
  position: absolute;
  text-align: center;
  line-height: 25px;
  letter-spacing: 1px;
  color: #f0f0f0;
  z-index: 9999;
  background: #555;
  /* Custom styles */
  /* Different positions */
  /* Colors */
}
:host .corner-ribbon.sticky {
  position: fixed;
}
:host .corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
:host .corner-ribbon.bottom-right {
  top: initial;
  bottom: 15px;
  left: initial;
  right: -50px;
  transform: rotate(-45deg);
}
:host .corner-ribbon.top-right {
  top: 15px;
  right: -50px;
  transform: rotate(45deg);
}
:host .corner-ribbon.top-left {
  top: 15px;
  left: -50px;
  transform: rotate(-45deg);
}
:host .corner-ribbon.white {
  background: #f0f0f0;
  color: #555;
}
:host .corner-ribbon.black {
  background: #333;
}
:host .corner-ribbon.grey {
  background: #999;
}
:host .corner-ribbon.blue {
  background: #39d;
}
:host .corner-ribbon.green {
  background: #2c7;
}
:host .corner-ribbon.turquoise {
  background: #1b9;
}
:host .corner-ribbon.purple {
  background: #95b;
}
:host .corner-ribbon.red {
  background: #e43;
}
:host .corner-ribbon.orange {
  background: #e82;
}
:host .corner-ribbon.yellow {
  background: #ec0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
