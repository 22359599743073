// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-content {
  display: flex;
  flex-direction: column;
}
:host ion-content img {
  display: flex;
  margin: auto;
  width: 6rem;
  height: 6rem;
}
:host ion-content .text-wrapper {
  margin-top: var(--space-m);
}
:host ion-content .text-wrapper span {
  font-size: var(--font-size-m);
  font-weight: bold;
}
:host ion-content .text-wrapper .subtext-wrapper {
  display: flex;
  flex-direction: row;
}
:host ion-content .text-wrapper .subtext-wrapper span {
  font-weight: normal;
  font-size: var(--font-size-s);
  margin: var(--space-m) 0;
}
:host ion-content .text-wrapper .subtext-wrapper span:last-child:not(:first-child) {
  color: var(--ion-color-primary);
}
:host ion-content .options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
:host ion-content .options-wrapper ion-button {
  text-transform: initial;
  font-size: var(--font-size-m);
  font-weight: normal;
  margin-bottom: var(--space-xxs);
}
:host ion-content .options-wrapper ion-button ion-icon {
  margin-right: var(--space-xxs);
}
:host ion-content .options-wrapper ion-button[fill=outline] {
  --border-width: 0;
  --background-hover: transparent;
}
:host ion-content .options-wrapper div {
  display: flex;
  align-items: center;
  font-size: var(--font-size-s);
  margin-top: var(--space-xxs);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
