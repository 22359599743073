/* eslint-disable @typescript-eslint/naming-convention */
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { User } from '@models/user.model';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/common/config.service';
import { StorageService } from '@services/common/storage.service';
import { SettingsService } from '@services/settings.service';


@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    private static readonly TOKEN_JWT_KEY = 'JWT';

    public readonly tag = '[SecurityService]';


    public isAuthenticatedUpdate$: EventEmitter<boolean> = new EventEmitter<boolean>();
    public isAuthenticated: boolean = false;
    public tokenJwt: string;

    private apiKey: string = null;

    constructor(
        public http: HttpClient,
        private storageService: StorageService,
        private apiService: ApiService,
        private settingsService: SettingsService,
        private configService: ConfigService
    ) {
    }

    public me(): Observable<User> {
        console.log(this.tag, 'me()');
        return this.apiService.getUtilisateur(true);
    }

    public async meParsed(): Promise<User> {
        try {
            const user = await lastValueFrom(this.apiService.getUtilisateur()) as User;
            // console.log(this.tag, 'User collected from API ', user);
            if (user && user.id) {
                this.settingsService.setUser(user);
                return user;
            }
        } catch (err: unknown) {
            console.log(this.tag, err);
        }

        return undefined;
    }

    public logout(): Promise<any> {
        console.log(this.tag, 'logout()');
        this.tokenJwt = undefined;
        this.storageService.set(SecurityService.TOKEN_JWT_KEY, undefined);
        this.settingsService.setUser(undefined);
        return lastValueFrom(this.http.post(this.configService.getConfig().BASE_API + 'api/auth/logout', {}));
    }

    public getAuthHeader(): HttpHeaders {
        let headers = new HttpHeaders();
        if (!!this.apiKey) {
            headers = headers.set('X_API_KEY', this.apiKey);
        }
        return headers;
    }
}
