export default {
  "APPNAME": "SAM",
  "ENVIRONMENT": "develop",
  "BASE_API": "https://dev.sam-transilien.sncf.fr/backend/",
  "MOBILE_URL": "https://dev.sam-transilien.sncf.fr/mobile",
  "SNCF_ID": {
    "REDIRECT_URI": "https://dev.sam-transilien.sncf.fr/backend/api/auth/authenticate/oidc"
  },
  "PIANO_ANALYTICS": {
    "ID": "640282"
  },
  "ARTICLE_PAGE_SIZE": 10,
  "SEARCH_RESULTS_SIZE": 20,
  "CONTACT_DESTINATAIRE": "contact.sam@sncf.fr"
};
